import { FC } from "react";
import { IconButton, Link, Tooltip } from "@material-ui/core";
import { FileCopy } from "@material-ui/icons";
import copy from "clipboard-copy";

type Properties = {
  did: string;
};

const showCharacters = 18;
const urlPrefix = "https://identity.foundation/ion/explorer/?did=";

const DidLink: FC<Properties> = ({ did }) => {
  const didShort = `${did.substring(0, showCharacters)}...`;

  return (
    <div>
      <Link
        href={`${urlPrefix}${encodeURIComponent(did)}`}
        target="_blank"
        rel="noopener"
        variant="body1"
      >
        {didShort}
      </Link>
      <Tooltip title="Copy to clipboard">
        <IconButton
          color="primary"
          size="medium"
          component="span"
          onClick={() => copy(did)}
        >
          <FileCopy fontSize="small" />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DidLink;
