import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import { FC } from "react";
import QRCode from "react-qr-code";
import { isAndroid, isIOS, isIOS13 } from "react-device-detect";
import { RequestResponse } from "../services/request";
import AuthenticatorButton from "./AuthenticatorButton";

type Properties = {
  value?: RequestResponse;
};

const size = 256;

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: size,
  },
  progress: {
    width: size,
    height: size,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  qrcode: {
    display: "block",
  },
}));

const AuthenticatorQrCode: FC<Properties> = ({ value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {!!!value ? (
        <div className={classes.progress}>
          <CircularProgress size="6rem" />
        </div>
      ) : (
        <div>
          <div>
            <QRCode value={value.ref} size={size} />
            <Typography variant="caption" component="p">
              ID: {value.id}
            </Typography>
          </div>
          <div>
            {(isAndroid || isIOS || isIOS13) && (
              <AuthenticatorButton url={value.ref} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuthenticatorQrCode;
