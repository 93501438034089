import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core";
import { FC, useEffect, useState } from "react";
import { createIssuerRequest, RequestResponse } from "../services/request";
import AuthenticatorQrCode from "./AuthenticatorQrCode";
import NavButton from "./NavButton";
import TabPanel from "./TabPanel";

const useStyles = makeStyles((theme) => ({
  navButton: {
    marginTop: theme.spacing(4),
  },
}));

const IssuerPanel: FC = () => {
  const classes = useStyles();
  const [request, setRequest] = useState<RequestResponse>();

  useEffect(() => {
    if (!!!request) {
      createIssuerRequest().then((response) => setRequest(response));
    }
  }, [request]);

  return (
    <TabPanel>
      <List>
        <ListItem>
          <ListItemText>
            1. Install the Microsoft Authenticator App for Android or iOS
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            2. In the app, add an account and choose "Other account".
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>3. Scan the image below</ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText disableTypography={true}>
            <AuthenticatorQrCode value={request} />
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemText>
            4. Complete the signup / signin process to receive your card.
          </ListItemText>
        </ListItem>
      </List>
      <NavButton className={classes.navButton} to="/verifier">
        Next: Validate Verifiable Credentials
      </NavButton>
    </TabPanel>
  );
};

export default IssuerPanel;
