import { makeStyles, Button } from "@material-ui/core";
import { FC } from "react";
import { ReactComponent as AuthenticatorIcon } from "../images/microsoft-authenticator.svg";

type Properties = {
  url: string;
};

const useStyles = makeStyles((theme) => ({
  authenticatorIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
}));

const AuthenticatorButton: FC<Properties> = ({ url }) => {
  const classes = useStyles();

  const openAuthenticator = () => {
    window.location.href = url;
  };

  return (
    <Button
      variant="outlined"
      color="primary"
      startIcon={<AuthenticatorIcon className={classes.authenticatorIcon} />}
      onClick={openAuthenticator}
    >
      Open Authenticator app
    </Button>
  );
};

export default AuthenticatorButton;
