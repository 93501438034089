import { FC } from "react";
import {
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Link,
} from "@material-ui/core";
import TabPanel from "./TabPanel";
import NavButton from "./NavButton";
import DifLogo from "../images/dif-logo.png";
import MicrosoftLogo from "../images/ms-logo.png";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "left",
  },
  logo: {
    width: theme.spacing(5),
    marginRight: theme.spacing(2),
  },
  nitorLogo: {
    width: theme.spacing(3),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(3),
  },
  navButton: {
    marginTop: theme.spacing(4),
  },
}));

const IntroductionPanel: FC = () => {
  const classes = useStyles();

  return (
    <TabPanel>
      <Typography align="justify" gutterBottom>
        This demo app allows you to issue yourself a Verified Digital Engineer
        VC or Verifiable Credential by Nitor. To accomplish this, you'll need to
        have the Microsoft Authenticator App for Android or iOS installed.
        <br />
        <br />
        In the issuer section you'll issue yourself a Verifiable Credential,
        after which you'll present that same credential in the verifier section
        to prove you truly are a Verified Digital Engineer.
        <br />
        <br />
      </Typography>
      <Typography variant="h6">Learn more:</Typography>
      <List>
        <ListItem>
          <ListItemAvatar>
            <img className={classes.logo} src={MicrosoftLogo} alt="Microsoft" />
          </ListItemAvatar>
          <ListItemText>
            <Link href="https://docs.microsoft.com/en-us/azure/active-directory/verifiable-credentials/">
              Microsoft Azure Active Directory Verifiable Credentials
            </Link>
          </ListItemText>
        </ListItem>
        <ListItem>
          <ListItemAvatar>
            <img
              className={classes.logo}
              src={DifLogo}
              alt="Decentralized Identity Foundation"
            />
          </ListItemAvatar>
          <ListItemText>
            <Link href="https://identity.foundation/">
              Decentralized Identity Foundation
            </Link>
          </ListItemText>
        </ListItem>
      </List>
      <Typography></Typography>
      <NavButton className={classes.navButton} to="/issuer">
        Next: Issue Verifiable Credentials
      </NavButton>
    </TabPanel>
  );
};

export default IntroductionPanel;
