import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/fonts.css";

let theme = createMuiTheme({
  typography: {
    fontFamily: ["Roobert", "sans-serif"].join(","),
  },
});
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
