import axios from "axios";
import { v4 } from "uuid";

export interface RequestResponse {
  id: string;
  ref: string;
}

export interface VerifierResponse {
  id: string;
  id_token: string;
  verified_credential: any;
}

export const createIssuerRequest: () => Promise<RequestResponse> = async () => {
  try {
    const url = `/api/issuer-request/${v4()}`;
    const response = await axios.post<RequestResponse>(url, {});
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createVerifierRequest: () => Promise<RequestResponse> =
  async () => {
    try {
      const url = `/api/verifier-request/${v4()}`;
      const response = await axios.post<RequestResponse>(url, {});
      return response.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

export const getPermissionResult: (
  id: string
) => Promise<VerifierResponse | null> = async (id) => {
  try {
    const url = `/api/permission-response/${id}`;
    const response = await axios.get<VerifierResponse>(url);
    return response.data;
  } catch (error: any) {
    if (error.isAxiosError && error.response.status === 404) {
      return null;
    }
    throw error;
  }
};
