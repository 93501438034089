import { FC, Fragment, useEffect, useState } from "react";
import {
  Link as HtmlLink,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import moment from "moment";
import useInterval from "../hooks/useInterval";
import {
  createVerifierRequest,
  getPermissionResult,
  RequestResponse,
  VerifierResponse,
} from "../services/request";
import AuthenticatorQrCode from "./AuthenticatorQrCode";
import DidLink from "./DidLink";
import Labeled from "./Labeled";
import NavButton from "./NavButton";
import TabPanel from "./TabPanel";
import CardImage from "../images/card.png";

const useStyles = makeStyles((theme) => ({
  navButton: {
    marginTop: theme.spacing(4),
  },
  presentation: {
    textAlign: "center",
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  presentationCard: {
    width: "80%",
  },
}));

const VerifierPanel: FC = () => {
  const classes = useStyles();
  const [request, setRequest] = useState<RequestResponse>();
  const [pollDelay, setPollDelay] = useState<number | null>(5000);
  const [result, setResult] = useState<VerifierResponse>();

  useEffect(() => {
    if (!!!request) {
      createVerifierRequest().then((response) => setRequest(response));
    }
  }, [request]);

  useInterval(
    () => {
      if (!!request) {
        getPermissionResult(request?.id).then((response) => {
          if (!!response) {
            setResult(response);
            setPollDelay(null);
          }
        });
      }
    },
    !!!result ? pollDelay : null
  );

  const getName = () => {
    const details =
      result &&
      result.verified_credential &&
      result.verified_credential.vc &&
      result.verified_credential.vc.credentialSubject;
    return details ? `${details.firstName} ${details.lastName}` : "Foo Bar";
  };

  const getExpires = () => {
    const exp: number | undefined =
      result && result.verified_credential && result.verified_credential.exp;
    return exp ? moment.unix(exp).toISOString() : "-";
  };

  const getIssuer = () => {
    const iss: string =
      result && result.verified_credential && result.verified_credential.iss;
    return iss || "-";
  };

  const getSubject = () => {
    const sub: string =
      result && result.verified_credential && result.verified_credential.sub;
    return sub || "-";
  };

  return (
    <TabPanel>
      {!!!result ? (
        <Fragment>
          <List>
            <ListItem>
              <ListItemText>
                1. Open the Microsoft Authenticator App
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                2. In the app, select "Credentials" from the menu.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                3. Scan the image below by pressing the scan icon.
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText disableTypography={true}>
                <AuthenticatorQrCode value={request} />
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                4. Accept the permission request, wait for confirmation.
              </ListItemText>
            </ListItem>
          </List>
          <NavButton className={classes.navButton} to="/issuer">
            Prev: Issue Verifiable Credentials
          </NavButton>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="h4" gutterBottom>
            Congratulations {getName()}.
            <br />
            You have proven yourself to be a Verified Digital Engineer.
          </Typography>
          <img
            className={classes.presentationCard}
            src={CardImage}
            alt="Verified Digital Engineer"
          />
          <Paper className={classes.presentation}>
            <Typography variant="h6" gutterBottom>
              Presentation details
            </Typography>
            <Labeled label="Expires">
              <Typography>{getExpires()}</Typography>
            </Labeled>
            <Labeled label="Issuer DID">
              <DidLink did={getIssuer()} />
            </Labeled>
            <Labeled label="Subject DID">
              <DidLink did={getSubject()} />
            </Labeled>
            <Typography>
              View complete presentation:{" "}
              <HtmlLink
                href={`https://jwt.ms/#access_token=${result.id_token}`}
                target="_blank"
                rel="noopener"
                variant="body1"
              >
                jwt.ms
              </HtmlLink>
            </Typography>
          </Paper>
          <Typography variant="h5">
            For more pragmatic alternatives to tin foil, visit{" "}
            <HtmlLink href="https://nitor.com">nitor.com</HtmlLink>
          </Typography>
        </Fragment>
      )}
    </TabPanel>
  );
};

export default VerifierPanel;
