import { makeStyles, Typography } from "@material-ui/core";
import { FC } from "react";

type Properties = {
  label: string;
  children: JSX.Element | JSX.Element[] | string | string[];
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: theme.spacing(5),
  },
  label: {
    marginRight: theme.spacing(1),
  },
}));

const Labeled: FC<Properties> = ({ label, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}:</Typography>
      {children}
    </div>
  );
};

export default Labeled;
