import { makeStyles } from "@material-ui/core";
import { FC } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
}));

type Properties = {
  children: JSX.Element | JSX.Element[] | string | string[];
};

const TabPanel: FC<Properties> = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.root}>{children}</div>;
};

export default TabPanel;
