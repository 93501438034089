import React, { FC, Fragment } from "react";
import {
  makeStyles,
  Container,
  CssBaseline,
  Link as HtmlLink,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import { ReactComponent as NitorLogo } from "./images/Nitor-RockBlack.svg";
import IntroductionPanel from "./components/IntroductionPanel";
import IssuerPanel from "./components/IssuerPanel";
import VerifierPanel from "./components/VerifierPanel";

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: "center",
  },
  logo: {
    width: theme.spacing(16),
    height: theme.spacing(16),
  },
  title: {},
  tabs: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  info: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(4),
  },
}));

const App: FC = () => {
  const classes = useStyles();
  const routes = ["/", "/issuer", "/verifier"];

  return (
    <React.Fragment>
      <CssBaseline />
      <Container className={classes.root} maxWidth="sm">
        <NitorLogo className={classes.logo} />
        <Typography className={classes.title} variant="h3">
          Verified Digital Engineer
        </Typography>
        <Router>
          <Route
            path="/"
            render={({ location }) => (
              <Fragment>
                <Tabs
                  className={classes.tabs}
                  value={location.pathname !== "/" ? location.pathname : "/"}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="fullWidth"
                  centered
                >
                  <Tab
                    value={routes[0]}
                    label="Introduction"
                    component={Link}
                    to={routes[0]}
                  />
                  <Tab
                    value={routes[1]}
                    label="Issuer"
                    component={Link}
                    to={routes[1]}
                  />
                  <Tab
                    value={routes[2]}
                    label="Verifier"
                    component={Link}
                    to={routes[2]}
                  />
                </Tabs>
                <Switch>
                  <Route path="/issuer">
                    <IssuerPanel />
                  </Route>
                  <Route path="/verifier">
                    <VerifierPanel />
                  </Route>
                  <Route path="/">
                    <IntroductionPanel />
                  </Route>
                </Switch>
              </Fragment>
            )}
          />
        </Router>
        <Typography
          className={classes.info}
          variant="caption"
          component="p"
          align="center"
        >
          Source code available in{" "}
          <HtmlLink href="https://github.com/NitorCreations/nitor-verifiable-credentials">
            GitHub
          </HtmlLink>
          <br />
          <strong>Minimum MS Authenticator Version:</strong>
          <br />
          Android: 6.2102.1274, IOS: 6.5.38
        </Typography>
      </Container>
    </React.Fragment>
  );
};

export default App;
